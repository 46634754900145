import React from 'react';
import styled from 'styled-components';
import {Modal, Button, Input} from 'antd';
import {useOutlet} from 'reconnect.js';
import {StaticImage} from 'gatsby-plugin-image';
import Logo from '../../images/LogoLight.png';
import ThemeContext from '../../Theme';

function License() {
  return (
    <Wrapper>
      <h1>{USER.title}</h1>
      <NewlineText text={USER.content} />

      <hr style={{width: '80%'}} />
      <br />

      <h1>{PRIVACY.title}</h1>
      <NewlineText text={PRIVACY.content} />
    </Wrapper>
  );
}

function NewlineText(props) {
  const text = props.text;
  const newText = text.split('\n').map((str) => <p>{str} </p>);
  return <div style={{whiteSpace: 'pre-wrap'}}>{newText}</div>;
}

const Wrapper = styled.div`
  padding: 20px;
`;

export default License;

// ---------------------------- //
const USER = {
  title: '使用者條款',
  content: `
    感謝您使用「FreeStay 雲端排號助手」（以下或稱「本服務」、「本系統」），本使用者條款有助於定義我們與您的關係。

    本文之「我們」意指「忻旅科技股份有限公司」本身、股東、董事、員工、主管及授權人。
    
    您必須具有完全行為能力，若您為限制或無行為能力人，應在您的法定代理人閱讀、暸解並同意本協議之所有內容及其後修改變更後，方得使用或繼續使用本服務；當您使用或繼續使用本服務時，即推定您的法定代理人已充分閱讀、暸解並同意接受本協議之所有內容及其後修改變更。
    
    您確認並同意，授予關於使用者內容的永久授權 (包含回饋) 是不可撤銷的。我們得隨時終止本協議或暫停您對使用 FreeStay 服務。於本協議終止後（不論來自您或我們），我們得移除或永久刪除您的帳戶資訊以及使用者內容，且在相關法律允許的最大範圍內，您同意我們對您無任何賠償義務或責任，且將不退還任何您已支付的款項。
    
    [您的行為準則]
    
    本服務與內容皆為「忻旅科技股份有限公司」或 「忻旅科技股份有限公司」之授權人的財產與權利。
    
    本服務可讓您與其他人互動交流。為了維護所有人互相尊重的環境，請務必恪守下列基本行為準則： 
    - 遵守適用法律，包括出口管制、制裁和人口販運法
    - 尊重他人的權利，包括隱私權和智慧財產權
    - 切勿辱罵或傷害他人或自己 (或是威脅或鼓吹他人從事這些行為)，例如誤導、詐騙、誹謗、霸凌、騷擾或跟蹤他人
    - 切勿濫用、破壞、干擾服務，或是導致服務中斷
    我們在此謹授予您有限、非專屬、可撤銷的服務使用權利。這些使用權利持續有效，直至您或我們終止這些使用權利為止。您承諾並同意使用內容做為個人、商業與合法用途。
    
    FreeSaty 的商標、服務標誌、商業名稱、徽標、網域名稱以及品牌的其他特色為「忻旅科技股份有限公司」及其授權人的獨有財產與權力。本協議並未授予您使用品牌特色的任何權力，無論是作商業或非商業之用。您亦並未得到使用 FreeStay 時產生之內容資訊的所有權及其他權利或利益。
    
    此外，對於您在 FreeStay 使用中發布的所有使用者內容，您必須擁有權限發布該內容，同時不會違反本協議、相關法律，及任何第三人之智慧財產權 (包含但不限於著作權)、公開權、人格權或其他權利，亦不得暗示我們或任何藝人、團體、別名、實體或個人，與您或您的使用者內容之間，存在任何聯繫關係或為您背書。
    
    「忻旅科技股份有限公司」或 「忻旅科技股份有限公司」之授權人得（但無義務）檢視或編輯使用者內容。在任何情況下，無論是否有任何理由，我們保留移除或停用存取任何使用者內容的權利，包含但不限於我們自行認定為違反本協議的使用者內容。我們可能在未經事先通知您或第三方的情況下採取這些行動。移除或停用使用者內容的存取應由我們自行酌情決定，且我們並未承諾移除或停用對任何特定使用者內容的存取。
    
    [我們被授與之權利]
    
    1. 允許我們使用您裝置上的處理器、頻寬與儲存硬體以便利服務的操作
    2. 允許我們的業務夥伴使用您留存的資訊與您聯繫、提供您廣告與其他資訊，
    3. 允許我們蒐集、存取與使用您利用本服務所主動留存的個人資料或使用者內容
    4. 以上權利授予我們非專屬、可轉讓、可再授權、免權利金、永久的、不可撤銷、全額付清的全球許可權，以便以任何形式透過任何媒介使用、複製、公開 (例如表演或展示)、出版、翻譯、修改、創作衍生作品、以及發行任何關於本服務的使用者內容，無論做法是單獨還是結合其他內容或資料，是透過任何現在已知還是之後再建立的方式、方法、技術。
    5. 您完全授予我們所有權利的所有權，包括使用者內容中的智慧財產權。在適用時及受相關法律許可之下，您亦同意放棄任何「著作人格權」(或根據適用法律的同等權利)。
    
    [免責聲明]
    
    我們將盡可能努力維持 FreeStay 運作。您理解且不追究偶爾會遇到特定的技術困難、需要維護或其他天災與不可抗力之因素等導致之暫時或永久中斷。 我們保留在通知或不通知的情況下，定期或隨時修改或中止（暫時或永久）服務之功能與特色的權利，且對您並無賠償責任。儘管有前述規定，若您已預付訂購之費用，但 於預付期間結束之前永久中止，我們將於該中止後退還預付期間未使用或抵扣的預付款。您理解、同意並接受我們沒有義務維護、支援、升級或更新服務，或是透過服務提供所有或任何特定內容。
    
    您使用 FreeStay 服務後與第三方或其他合作夥伴若有利害關係及一切糾紛亦與我們無關。且我們無需為任何第三方應用程式 (或其內容)、使用者內容、或透過第三方服務、任何超連結網站、或刊登於任何橫幅廣告或其他廣告的第三方廣告或促銷的任何其他產品或服務負責或背書。
    
    您同意若對於 FreeStay 服務有任何不滿意之處，您僅能以終止使用我們服務為處置方法。於法律的允許的最大範圍內，忻旅科技股份有限公司本身、主管、股東、員工、代理人、董事、子公司、附屬公司、繼承人、受讓人、供應商或授權人將不負責
    1. 任何間接、特殊、附帶、懲罰性、懲戒性或衍生性之損害
    2. 所有因使用或未能使用 FreeStay 服務及相關第三方應用程式或內容而引起的任何使用、資料、業務或利潤 (無論直接或間接) 的所有損失
    
    [條款之變更]
    
    本條款將因應需求隨時進行修正，修正後的條款將發布於網站上。
    `,
};

const PRIVACY = {
  title: '隱私權政策',
  content: `
    感謝您使用「FreeStay 雲端排號助手」（以下或稱「本服務」、「本系統」），為了幫助您瞭解本系統如何蒐集、應用及保護您的個人資訊，請仔細閱讀本文件。

[適用範圍]

本平台服務包含兩種角色
1. 商家：意指提供實體（或虛擬）服務之廠商，實務上控制號碼之增減
2. 顧客：意指尋求商家提供服務，號碼決定其接受服務之順序


[政策內容]

我們將可能從「商家」及「顧客」收取資料
1. 註冊資料：因服務需求（如避免誤導）我們將會索取部分資訊，如商家之實體資訊或顧客之社群登入資訊。
2. 使用偏好及足跡：為提供更好之服務，我們可能會利用商家及用戶在系統上之使用紀錄，除自行運用使用者的個人資料之外，也可能基於包括（但不限於）金流處理、客戶服務、以及行銷等目的，提供第三方合作夥伴利用。利用方式可能為資訊傳遞、與使用者直接聯繫、或提供使用者蒐集利用目的相關的訊息或服務。

[資料保護]

如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其 遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。

[諮詢]

如果您對本文所述之條款及內容有任何疑問或意見，歡迎與「忻旅科技股份有限公司」聯繫。

[隱私權條款之修正]

本政策將因應需求隨時進行修正，修正後的條款將發布於網站上。



    `,
};
